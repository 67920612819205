/**
 * Watch and Hand Image Component
 */

import React from 'react'
import Image from '../../components/image'

class ImageWatchHand extends React.Component {
  render() {
    return (
      <div
        style={{
          height: `150px`,
          width: `180px`,
        }}>
        <Image alt="Watson hand holding a watch" filename="watch.png" />
      </div>
    )
  }
}
export default ImageWatchHand
